import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MainBtn from "../components/button/mainBtn"
import Phone from "../assets/images/subscribed/newsletter-bg-big.png"
import PhoneMobile from "../assets/images/subscribed/mobile.svg"
import TabletBG from "../assets/images/subscribed/ipad.svg"

const Subcribed = () => {
  return (
    <Layout className="subscribed bg-design vector-dash-yellow">
      <SEO pageTitle="Subscribed" title="Subscribed | Servbees" />
      <div className="section-coming-soon bg-design vector-dash">
        <div className="container landing-wrapper">
          <div className="content-wrapper">
            <div className="content-holder">
              <div className="text-holder">
                <h2 className="title-heading" style={{ lineHeight: "unset" }}>
                  Thanks for subscribing!
                </h2>
                <p className="desc">
                  We're bee-yond happy that you subscribed to the Servbees
                  newsletter!
                </p>
              </div>
              <div className="btn-holder">
                <Link to="/">
                  <MainBtn text="Back to Home" type="button" />
                </Link>
              </div>
            </div>
            <div className="content-holder">
              <div className="image-holder">
                <img src={Phone} alt="" className="phone-ui" />
                <img src={PhoneMobile} alt="" className="phone-ui-mobile" />
                <img src={TabletBG} alt="" className="bg-tablet" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Subcribed
